export default [
	{
		path: "decision",
		meta: {
      helpTag: "college:decision",
    },
    component: () =>
			import(
				"../../views/general/decision"
			),
			children: [
				{
					path: ":hexkey/:bsi_hexkey([a-z0-9]{32})?",
					name: "College.Decision.Details",
					component: () =>
						import(
							"../../views/general/decision/decision.vue"
						),
				},
			],
  },
];