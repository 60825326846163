<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :max-width="isSuccess ? '450px' : '900px'"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-btn
        v-if="!dialogData.isListItem"
        :color="dialogData.buttonColor || 'primary'"
        v-on="dialog"
        :text="dialogData.linkText && dialogData.linkText.length > 0"
        :icon="dialogData.linkIcon && !dialogData.linkText"
        :disabled="disabled"
        :class="dialogData.buttonClass"
        :aria-label="ariaLabelText"
        :title="ariaLabelText"
        aria-haspopup="dialog"
        class="btn-focus"
      >
        <v-icon
          v-if="dialogData.linkIcon"
          small
          :left="dialogData.linkText != null"
          :class="dialogData.iconClass"
          :color="dialogData.iconColor"
          aria-hidden="true"
          >{{ dialogData.linkIcon }}
        </v-icon>

        {{ dialogData.linkText || dialogData.titleText }}
      </v-btn>
      <v-list-item v-else v-bind="attrs" v-on="{ ...dialog }">
        <v-list-item-icon v-if="dialogData.linkIcon">
          <v-icon>{{ dialogData.linkIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="dialogData.linkText"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title v-if="!isSuccess">
        <v-row justify="space-between" class="flex-nowrap">
          <v-col>
            <h1 class="dw-text-h5">{{ titleText ? titleText : editText }}</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              :title="closeText"
              :aria-label="closeText"
              icon
              @click="dialog = false"
              class="btn-focus"
            >
              <v-icon aria-hidden="true">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-left">
        <h2 v-if="!isSuccess" class="text-subtitle-2">{{ $translations.validation.requiredFields }}</h2>
        <formFields
          v-if="!isSuccess && !isAnnex"
          ref="formField"
          :module="module"
          :hexkey="hexkey"
          :isDialog="true"
          :isVisible="dialog"
          :requestParams="requestParams"
          @submit="processSubmit"
          @startSubmit="submitLoading = true"
          @errorSubmit="submitLoading = false"
          @errorChange="processError"
        ></formFields>
        <annexFormFields
          v-if="!isSuccess && isAnnex"
          ref="formField"
          :module="module"
          :hexkey="hexkey"
          :isDialog="true"
          :isVisible="dialog"
          :requestParams="requestParams"
          @submit="processSubmit"
          @startSubmit="submitLoading = true"
          @errorSubmit="submitLoading = false"
          @errorChange="processError"
        ></annexFormFields>
        <success v-if="isSuccess" :successMessage="successMessage" />
      </v-card-text>
      <v-card-actions v-if="!isSuccess">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="dialog = false"
          :disabled="loading"
          class="btn-focus"
        >
          {{ dialogData.cancelText || defaultCancelButtonText }}
        </v-btn>
        <v-btn
          color="primary"
          @click="$refs.formField.submitData()"
          :loading="loading || submitLoading"
          :aria-label="dialogData.submitText || defaultSubmitButtonText"
          :title="dialogData.submitText || defaultSubmitButtonText"
          aria-haspopup="dialog"
          class="btn-focus"
        >
          {{ dialogData.submitText || defaultSubmitButtonText }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else class="px-10 pb-10">
        <v-btn
          color="primary"
          block
          @click="dialog = false"
          :disabled="loading"
          :aria-label="$translations.actions.closeFormDialog"
          :title="$translations.actions.closeFormDialog"
          class="btn-focus"
        >
          {{ $translations.actions.closeFormDialog }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formFields from "./formFields";
import annexFormFields from "./annexFormFields";
import success from "./success.vue";

export default {
  name: "General.Forms.FormInDialog",
  data: () => ({
    dialog: false,
    loading: false,
    submitLoading: false,
    detailData: [],
    fieldsWithErrors: [],

    isSuccess: false,
  }),
  props: {
    dialogData: {
      type: Object,
      default: () => {
        return {
          isListItem: false,
          linkText: null,
          linkIcon: null,
          ariaLabel: null,
          buttonColor: null,
          buttonClass: null,
          iconColor: null,
          iconClass: null,
          cancelText: null,
          submitText: null,
          editText: null,
          titleText: null,
        };
      },
    },
    title: String,
    module: String,
    hexkey: String,
    successMessage: String,
    requestParams: Object,
    isVisible: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    isAnnex: Number,
  },
  components: {
    success,
    formFields,
    annexFormFields,
  },
  created() {
    if (this.isVisible) {
      this.dialog = true;
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.loading = false;
        this.submitLoading = false;
        this.$emit("close");
      } else {
        this.isSuccess = false;
      }
    },
  },
  computed: {
    titleText: function () {
      if (this.title) {
        return this.title;
      }

      return this.detailData.length > 0
        ? this.detailData.find((item) => item.type === "title").text
        : null;
    },
    defaultSubmitButtonText() {
      return this.$store.getters.getTranslations.editDialog.submitButton;
    },
    defaultCancelButtonText() {
      return this.$store.getters.getTranslations.editDialog.cancelButton;
    },
    editText() {
      return this.$store.getters.getTranslations.actions.edit;
    },
    closeText() {
      return this.$store.getters.getTranslations.actions.close;
    },
    ariaLabelText() {
      return (
        this.dialogData.ariaLabel ||
        this.dialogData.linkText ||
        this.dialogData.titleText ||
        this.dialogData.editText ||
        this.editText
      );
    },
  },
  methods: {
    processSubmit() {
      this.isSuccess = true;
      // this.dialog = false;
      this.$emit("submit");
    },
    processError(errors) {
      this.fieldsWithErrors = errors;
    },
  },
};
</script>
