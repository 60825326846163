import store from '../store';
import moment from 'moment';

const currentVersion = process.env.PACKAGE_VERSION || '0';

const checkVersion = () => {
  console.log('CURRENTLY RUNNING V' + currentVersion);

  let resetVersions = store.getters.getReset.versions;
  if (
    !resetVersions.includes(currentVersion)
  ){
    resetStore();

    resetVersions.push(currentVersion);
    store.commit("setResetVersions", resetVersions);

    location.reload();
  }
};

const checkForceReset = (dateTime) => {
  const reset = store.getters.getReset;
  if (!reset.lastDate || moment(reset.lastDate).isBefore(moment(dateTime, 'DD-MM-YYYY hh:mm:ss'))){
    resetStore();
    location.reload();
  }
};

const resetStore = () => {
  console.log('CLEARING STORAGE');

  const reset = store.getters.getReset;

  store.dispatch('resetAccessibilityState');
  store.dispatch('resetCouncilCalendarState');
  store.dispatch('resetPublicCalendarState');
  store.dispatch('resetCouncilPeriodState');
  store.dispatch('resetGeneralState');
  store.dispatch('resetUserState');

  store.commit("setResetVersions", reset.versions);

  store.commit("setResetDate", moment().toISOString());
  console.log('STORAGE CLEARED');
};

export {
  checkVersion,
  checkForceReset,
};
