import calendarHelper from "../../helpers/calendar.js";

const getDefaultState = () => {
  return {
		events: [],
		fetchedData: {},
  };
};

const state = getDefaultState();

const getters = {
	getPublicCalendarEvents: (state) => state.events,
};

const actions = {
	async fetchPublicCalendarData({ commit, state }, { month, year }) {
		const events = await calendarHelper.fetchCalendarData('public', state, month, year);
		if (events) {
			commit("addPublicEvents", events.filter(event => event !== undefined));
		}
	},
	resetPublicCalendarState ({ commit }) {
    commit('resetPublicCalendarState');
  },
};

const mutations = {
	addPublicEvents: (state, events) => (state.events = state.events.concat(events)),
	resetPublicCalendarState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };