import vuetify from "../../plugins/vuetify";

const getDefaultState = () => {
  return {
    settings: {
      contentScaling: 0,
      fontSize: 0,
      letterSpacing: 0,
      lineHeight: 0,
    },

    darkmode: vuetify.framework.theme.isDark,
    highSaturation: false,
    monochrome: false,
    lowSaturation: false,
    highContrast: false,
    lightContrast: false,

  };
};

const state = getDefaultState();

const getters = {
  getSettings: (state) => state.settings,
  getDarkmode: (state) => state.darkmode,
  getHighSaturation: (state) => state.highSaturation,
  getMonochrome: (state) => state.monochrome,
  getLowSaturation: (state) => state.lowSaturation,
  getHighContrast: (state) => state.highContrast,
  getLightContrast: (state) => state.lightContrast,
};

const actions = {
  resetAccessibilityState ({ commit }) {
    commit('resetAccessibilityState');
  },
};


const mutations = {
	setSettings: (state, {name, value}) => (state.settings[name] = value),
  setDarkmode: (state, value) => (state.darkmode = value),
  setHighSaturation: (state, value) => (state.highSaturation = value),
  setMonochrome: (state, value) => (state.monochrome = value),
  setLowSaturation: (state, value) => (state.lowSaturation = value),
  setHighContrast: (state, value) => (state.highContrast = value),
  setLightContrast: (state, value) => (state.lightContrast = value),
  resetAccessibilityState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };
