import request from "./api";

function getModuleData() {
  return request({
    url: "/vji/general/session/action=moduledata",
    method: "GET",
  });
}

function doLogin({ userId, password }) {
  return request({
    url: "/vji/general/session/action=login",
    method: "POST",
    data: {
      userid: userId,
      pw: password,
    },
  });
}

function doLogout() {
  return request({
    url: "/vji/general/session/action=logout",
    method: "POST",
  });
}

function doChange(data) {
  return request({
    url: "/vji/general/session/action=changepw",
    method: "POST",
    data: data,
  });
}

function doReset({ email }) {
  return request({
    url: "/vji/general/pwdreset/action=pwdreset",
    method: "POST",
    data: {
      email: email,
    },
  });
}

function do2FA({ request_id, otp_code }) {
  return request({
    url: "/vji/general/session/action=2fa",
    method: "POST",
    data: {
      otp_code: otp_code,
      request_id: request_id,
    },
  });
}

function doNewPw(data) {
  return request({
    url: "/vji/general/session/action=newpw",
    method: "POST",
    data: data,
  });
}

function getSSOSession(uuid) {
  return request({
    url: "/vji/general/session/action=sso",
    method: "POST",
    data: {
      guid: uuid,
    },
  });
}

const generalAuthService = {
  doLogin,
  doLogout,
  doChange,
  doReset,
  doNewPw,
  getModuleData,
  do2FA,
  getSSOSession,
};

export default generalAuthService;
