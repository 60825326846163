<template>
  <v-alert
    v-if="text"
    :type="type"
    :color="$helpers.color.darkModeColor(color, strength)"
    text
    transition="scale-transition"
    origin="center center"
    :dense="dense"
    >{{ text }}
  </v-alert>
</template>

<script>
export default {
  props: {
    type: {
      default: "info",
    },
    text: {
      required: true,
      type: String,
    },
    color: {
      default: "primary",
    },
    strength: {
      default: 2,
    },
    dense: {
      default: true,
    },
  },
};
</script>

<style>
</style>