import { render, staticRenderFns } from "./formFields.vue?vue&type=template&id=3b93b69c&scoped=true"
import script from "./formFields.vue?vue&type=script&lang=js"
export * from "./formFields.vue?vue&type=script&lang=js"
import style0 from "./formFields.vue?vue&type=style&index=0&id=3b93b69c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b93b69c",
  null
  
)

export default component.exports