import store from "../store";

const rules = {
	required: () => {
		return (value) =>
			!!value || store.getters.getTranslations.validation.required;
	},
	email: () => {
		return (value) => {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return (
				pattern.test(value) ||
				store.getters.getTranslations.validation.emailInvalid
			);
		};
	},
	minLength(minNum) {
		return (v) =>
			(v || "").length >= minNum ||
			store.getters.getTranslations.validation.minLength.replace(
				"$AMOUNT",
				minNum
			);
	},
	maxLength(maxNum) {
		return (v) =>
			(v || "").length <= maxNum ||
			store.getters.getTranslations.validation.maxLength.replace(
				"$AMOUNT",
				maxNum
			);
	},
	inPast() {
		return (v) => {
			const date = new Date(v).getTime();
			const now = new Date().getTime();
			return (
				date < now || store.getters.getTranslations.validation.inPast
			);
		};
	},
	inFuture() {
		return (v) => {
			const date = new Date(v).getTime();
			const now = new Date().getTime();
			return (
				date > now || store.getters.getTranslations.validation.inFuture
			);
		};
	},
};

export { rules };
