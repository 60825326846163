<template>
  <div class="d-flex">
    <v-btn
      text
      small
      color="primary"
      class="pl-0 btn-focus"
      v-if="hasPrevious"
      :aria-label="$translations.back_label"
      @click="$router.go(-1)"
    >
      <v-icon class="mr-2">mdi-chevron-left</v-icon>
      {{ $translations.back_label }}
    </v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    hasPrevious() {
      return window.history.length > 1;
    },
  },
};
</script>

<style>
</style>