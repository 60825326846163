import moment from "moment";
import councilCalendarService from "../axios/council/calendar";
import publicCalendarService from "../axios/public/calendar";
import collegeCalendarService from "../axios/college/calendar";
import partyCalendarService from "../axios/party/calendar";

import colorHelper from "./color";

function formatEvents(events) {
  return events.map((event) => {
    const color = event.color != 0 ? event.color.split("_") : ["", "primary"];
  
    let gradient = "";
    if (color[0] == "dark") {
      gradient = colorHelper.darkModeTextColor("white", 4);
    } else if (color[0] == "light") {
      gradient = colorHelper.darkModeTextColor('black', 4, true);
    }

    const legendColor = event.legend_color != 0 ? event.legend_color.split("_") : ["", "primary"];
    let legendGradient = "";
    if (legendColor[0] == "dark") {
      legendGradient = colorHelper.darkModeTextColor("white", 4);
    } else if (legendColor[0] == "light") {
      legendGradient = colorHelper.darkModeTextColor('black', 4, true);
    }

    const formatDateTime = function(date, time = null){
      return time
      ? moment(date + " " + time, "YYYY/MM/DD HH:mm").format("YYYY-MM-DDTHH:mm")
      : moment(date, "YYYY/MM/DD").format("YYYY-MM-DD");
    };

    return {
      ...event,
      start: formatDateTime(event.startDate, event.startTime),
      end: formatDateTime((event.endDate || event.startDate), (event.endTime || event.startTime)),
      timed: true,
      color: colorHelper.darkModeColor(color[1]) + " " + gradient,
      legendColor: colorHelper.darkModeColor(legendColor[1]) + " " + legendGradient,
      moduleName: event.module_name,
    };
  });
}

async function fetchCalendarData(env, fetchedData, month, year){
  if (
    !(year in fetchedData) ||
    !fetchedData[year].includes(month)
  ) {
    if (!(year in fetchedData)) {
      fetchedData[year] = [];
    }
    fetchedData[year].push(month);

    // DEFAULT USE PUBLIC
    let service = publicCalendarService;
    if (env == 'council.calendar'){
      service = councilCalendarService;
    } else if (env == 'college.calendar'){
      service = collegeCalendarService;
    } else if (env == 'party.calendar'){
      service = partyCalendarService;
    }

    const date = moment(`${month}-${year}`, "MM-YYYY");
    const events = await service.getDataList({
      start: date.startOf("month").format("DD-MM-YYYY"),
      end: date.endOf("month").format("DD-MM-YYYY"),
    });

    return formatEvents(events.events);
  }
}

const calendarHelper = {
  fetchCalendarData,
};

export default calendarHelper;