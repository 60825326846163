import Vue from "vue";
import Vuetify from "vuetify-wcag/lib/framework";

import en from "vuetify-wcag/lib/locale/en";
import nl from "vuetify-wcag/lib/locale/nl";
import fr from "vuetify-wcag/lib/locale/fr";
import es from "vuetify-wcag/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, nl, fr, es },
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        error: '#C62828',
        background: '#FAFAFA',
      },
      dark: {
        error: '#FFCDD2',
        background: '#424242',
      },
    },
  },
  
});
