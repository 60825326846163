import request from "./api";

function getGeneralData() {
  return request({
    url: "/vji/general/app",
    method: "GET",
  });
}

function setLanguage(langCode) {
  return request({
    url: "/vji/general/languages/action=vset/ln=" + langCode,
    method: "GET",
  });
}

function getMenuTab(url) {
  return request({
    url: url + "/action=datalist",
    method: "GET",
  });
}

function getUserData() {
  return request({
    url: "/vji/general/session/action=userdata",
    method: "GET",
  });
}

const generalService = {
  getGeneralData,
  setLanguage,
  getMenuTab,
  getUserData
};

export default generalService;
