import store from "../store";

function filenameToIcon(name){
  const ext = name.split('.').pop();

  switch (ext) {
    case 'pdf':
      return 'mdi-file-pdf-box';

    case 'txt':
    case 'md':
      return 'mdi-file-document';

    case 'doc':
    case 'docx':
      return 'mdi-file-word';

    case 'ppt':
    case 'pptx':
      return 'mdi-file-powerpoint';

    case 'xls':
    case 'xlsx':
      return 'mdi-file-excel';

    case 'xml':
      return 'mdi-file-xml-box';

    case 'zip':
    case 'rar':
      return 'mdi-zip-box';

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'mdi-image';

  }

  if (name.includes('action=') || name.includes('http') || name === "link"){
    return 'mdi-web';
  } else {
    return 'mdi-file-question';
  }
}

function filenameToIconLabel(name){
  const ext = name.split('.').pop();
  const translations = store.getters.getTranslations;

  switch (ext) {
    case 'pdf':
      return translations.aria_labels.pdf_file;

    case 'txt':
    case 'md':
      return translations.aria_labels.text_file;

    case 'doc':
    case 'docx':
      return translations.aria_labels.word_file;

    case 'ppt':
    case 'pptx':
      return translations.aria_labels.powerpoint_file;

    case 'xls':
    case 'xlsx':
      return translations.aria_labels.excel_file;

    case 'xml':
      return translations.aria_labels.xml_file;

    case 'zip':
    case 'rar':
      return translations.aria_labels.zip_file;

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return translations.aria_labels.image_file;

  }

  if (name.includes('action=') || name.includes('http') || name === "link"){
    return translations.aria_labels.url;
  } else {
    return translations.aria_labels.unknown_file;
  }
}

const iconHelper = {
	filenameToIcon,
  filenameToIconLabel,
};

export default iconHelper;