export default [
  {
    path: "/clerk",
    meta: { needsAuth: true, },
    component: function () {
      return import("../views/edit/clerk");
    },
    children: [
      {
        path: "",
        name: "Clerk.Homepage",
        component: () =>
          import("../views/edit/clerk/homepage"),
      },
      {
        path: "bestuursdocument",
        meta: {
          helpTag: "Clerk:bestuursdocument",
        },
        component: () =>
          import(
            "../views/edit/clerk/bestuursdocument"
          ),
        children: [
          {
            path: "annex/:hexkey?",
            name: "Clerk.BestuursDocument.AnnexList",
            component: () =>
              import(
                "../components/general/lists/edit/annexList"
              ),
          },
          {
            path: "extended/:hexkey?",
            name: "Clerk.BestuursDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/edit/extendedList"
              ),
          },
          {
            path: "publish/:hexkey?",
            name: "Clerk.BestuursDocument.PublishList",
            component: () =>
              import(
                "../components/general/lists/edit/publishList"
              ),
          },
          {
            path: "published/:hexkey?",
            name: "Clerk.BestuursDocument.PublishedList",
            component: () =>
              import(
                "../components/general/lists/edit/publishedList"
              ),
          },
          {
            path: ":hexkey?",
            name: "Clerk.BestuursDocument",
            component: () =>
              import(
                "../components/general/lists/edit/simpleList"
              ),
          },
          
        ],
      },
    ],
  },
];