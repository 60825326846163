import agenda from "./college/agenda.js";
import decision from "./college/decision.js";

export default [
  {
    path: "/college",
    meta: { needsAuth: true, },
    component: function () {
      return import("../views/college");
    },
    children: [
      {
        path: "",
        name: "College.Homepage",
        component: () =>
          import("../views/college/homepage"),
      },

      ...agenda,
      ...decision,

      {
        path: "actiepunten",
        meta: {
          helpTag: "college:actiepunten",
        },
        component: () =>
          import(
            "../views/college/actionpoints"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "College.ActionPoints.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "College.ActionPoints",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "bestuursdocument",
        meta: {
          helpTag: "college:bestuursdocument",
        },
        component: () =>
          import(
            "../views/college/bestuursdocument"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "College.BestuursDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "College.BestuursDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "collegeberichten",
        meta: {
          helpTag: "college:collegeberichten",
        },
        component: () =>
          import(
            "../views/college/collegeberichten"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "College.CollegeBerichten.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "College.CollegeBerichten",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "calendar/:hexkey?",
        meta: {
          helpTag: "college:calendar",
        },
        component: () =>
          import("../views/general/calendar"),
        children: [
          {
            path: "",
            name: "College.Calendar",
            component: () =>
              import("../views/general/calendar/calendar"),
          },
        ],
      },
      {
        path: "dagmap",
        meta: {
          helpTag: "college:dagmap",
        },
        component: () =>
          import(
            "../views/college/dagmap"
          ),
        children: [
          {
            path: "",
            name: "College.Dagmap",
            component: () =>
              import(
                "../components/college/dagmap/list"
              ),
          },
          {
            path: ":hexkey/:ap_hexkey([a-z0-9]{32})?",
            name: "College.Dagmap.Details",
            component: () =>
              import(
                "../components/college/dagmap/agenda"
              ),
          },
        ],
      },
      {
        path: "decisionbook",
        meta: {
          helpTag: "college:decisionbook",
        },
        component: () =>
          import(
            "../views/college/decisionbook"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "College.DecisionBook.ExtendedList",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "College.DecisionBook",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "postin",
        meta: {
          helpTag: "college:postin",
        },
        component: () =>
          import(
            "../views/college/postin"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "College.Postin.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "College.Postin",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "preferences",
        meta: {
          helpTag: "college:preferences",
        },
        component: () => import("../views/general/preferences"),
        children: [
          {
            path: "",
            name: "College.Preferences",
            component: () => import("../views/general/preferences/detail"),
          },
        ],
      },
      {
        name: "College.Search",
        path: "search",
        meta: {
          helpTag: "college:search",
        },
        component: () =>
          import("../views/general/search"),
      },
      {
        path: "dossier",
        meta: {
          helpTag: "college:dossier",
        },
        component: () =>
          import("../views/general/dossier"),
        children: [
          {
            path: "",
            name: "College.Dossier",
            component: () =>
              import(
                "../views/general/dossier/list"
              ),
          },
          {
            path: ":hexkey",
            name: "College.Dossier.Details",
            component: () =>
              import(
                "../views/general/dossier/details"
              ),
          },
        ],
      },
      {
        path: "event",
        meta: {
          helpTag: "college:event",
        },
        component: () =>
          import(
            "../views/college/event"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "College.Event",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "processeddossiers",
        meta: {
          helpTag: "college:processeddossiers",
        },
        component: () =>
          import("../views/college/processeddossiers"),
        children: [
          {
            path: ":hexkey?",
            name: "College.ProcessedDossiers",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/college/processeddossiers/list"
              ),
          },
        ],
      },
      {
        path: "mandate",
        meta: {
          helpTag: "college:mandate",
        },
        component: () =>
          import("../views/college/mandate"),
        children: [
          {
            path: ":hexkey?",
            name: "College.Mandate",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/college/mandate/list"
              ),
          },
        ],
      },
    ],
  },
];