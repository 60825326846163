export default [
	{
		path: "council-period",
		meta: {
      helpTag: "public:council-period",
    },
		component: () =>
			import(
				"../../views/council-public/councilperiod"
			),
		children: [
			{
				path: "",
				name: "Public.CouncilPeriod",
				component: () =>
					import(
						"../../views/council-public/councilperiod/list"
					),
			},
			{
				path: "member/:cp_hexkey/:cpm_hexkey/:tab?/:cpp_hexkey?",
				name: "Public.CouncilPeriod.Member",
				component: () =>
					import(
						"../../views/council-public/councilperiod/detail"
					),
			},
		],
	},
	{
		path: "council-period/party",
		meta: {
      helpTag: "public:council-period-party",
    },
		component: () =>
			import(
				"../../views/council-public/councilperiod/party"
			),
		children: [
			{
				path: ":cp_hexkey/:pa_hexkey/:cpp_hexkey?",
				name: "Public.CouncilPeriodParty",
				component: () =>
					import(
						"../../views/council-public/councilperiod/party/list"
					),
			},
			{
				path: "details/:cp_hexkey/:pa_hexkey",
				name: "Public.CouncilPeriodParty.DetailDialog",
				component: () =>
					import(
						"../../components/council-public/councilperiod/party/detailDialog"
					),
			},
		],
	},

	// fallback code. Verwijderen op/na 01-01-2024
	{
		path: "council-period/votes/:cp_hexkey/:cpm_hexkey/:cpp_hexkey?",
		redirect: (to) => {
			return {
				name: "Public.CouncilPeriod.Member",
				params: {
					cp_hexkey: to.params.cp_hexkey,
					cpm_hexkey: to.params.cpm_hexkey,
					cpp_hexkey: to.params.cpp_hexkey,
					tab: 'votes',
				},
			};
		},
	},
	{
		path: "council-period/documents/:cp_hexkey/:cpm_hexkey",
		redirect: (to) => {
			return {
				name: "Public.CouncilPeriod.Member",
				params: {
					cp_hexkey: to.params.cp_hexkey,
					cpm_hexkey: to.params.cpm_hexkey,
					cpp_hexkey: to.params.cpp_hexkey,
					tab: 'documents',
				},
			};
		},
	},
	{
		path: "council-period/videos/:cp_hexkey/:cpm_hexkey",
		redirect: (to) => {
			return {
				name: "Public.CouncilPeriod.Member",
				params: {
					cp_hexkey: to.params.cp_hexkey,
					cpm_hexkey: to.params.cpm_hexkey,
					tab: 'videos',
				},
			};
		},
	},
];
