import agenda from "./party/agenda.js";
import decision from "./party/decision.js";

export default [
  {
    path: "/party",
    meta: { needsAuth: true, },
    component: function () {
      return import("../views/party");
    },
    children: [
      {
        path: "",
        name: "Party.Homepage",
        component: () =>
          import("../views/party/homepage"),
      },

      ...agenda,
      ...decision,

      {
        path: "actiepunten",
        meta: {
          helpTag: "party:actiepunten",
        },
        component: () =>
          import(
            "../views/party/actionpoints"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "Party.ActionPoints.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Party.ActionPoints",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "calendar/:hexkey?",
        meta: {
          helpTag: "party:calendar",
        },
        component: () =>
          import("../views/general/calendar"),
        children: [
          {
            path: "",
            name: "Party.Calendar",
            component: () =>
              import("../views/general/calendar/calendar"),
          },
        ],
      },
      {
        path: "decisionbook",
        meta: {
          helpTag: "party:decisionbook",
        },
        component: () =>
          import(
            "../views/party/decisionbook"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "Party.DecisionBook.ExtendedList",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Party.DecisionBook",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      // {
      //   path: "preferences",
      //   meta: {
      //     helpTag: "party:preferences",
      //   },
      //   component: () => import("../views/general/preferences"),
      //   children: [
      //     {
      //       path: "",
      //       name: "Party.Preferences",
      //       component: () => import("../views/general/preferences/detail"),
      //     },
      //   ],
      // },
      // {
      //   name: "Party.Search",
      //   path: "search",
      //   meta: {
      //     helpTag: "party:search",
      //   },
      //   component: () =>
      //     import("../views/general/search"),
      // },
    ],
  },
];