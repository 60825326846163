const getDefaultState = () => {
  return {
    tab: null,
  };
};

const state = getDefaultState();

const getters = {
  getCouncilPeriodTab: (state) => state.tab,
};

const actions = {
	resetCouncilPeriodState ({ commit }) {
    commit('resetCouncilPeriodState');
  },
};

const mutations = {
	setCouncilPeriodTab: (state, tab) => (state.tab = tab),
  resetCouncilPeriodState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };
