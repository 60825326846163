<template>
  <div class="dw-on-top pa-5" :class="{ loader: true, fadeout: !isLoading }">
    <v-row
      v-if="!error"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="dw-text-subtitle-1 text-center" cols="12">
        {{ text[lang] }}
      </v-col>
      <v-col cols="6" md="4" lg="3" xl="2">
        <v-progress-linear
          color="black"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-else class="fill-height ma-10" justify="start">
      <v-col cols="12">
        <v-alert type="error" icon="mdi-alert-circle">{{
          error.message
        }}</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "loadingScreen",
  data() {
    return {
      error: null,
      text: {
        nl: "Informatie inladen",
        en: "Loading information",
        fr: "Chargement des informations",
        es: "Cargando información",
      },
    };
  },
  props: {
    isLoading: Boolean,
  },
  computed: {
    lang() {
      return this.$store.getters.getLanguage.substring(0, 2);
    },
    errors() {
      return this.$store.getters.getErrors;
    },
  },
  watch: {
    errors(newVal) {
      this.error = newVal[newVal.length - 1];
    },
  },
};
</script>

<style>
.loader {
  background-color: #fff;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.dw-on-top {
  z-index: 100000000;
}
</style>