import vuetify from "../plugins/vuetify.js";

/**
 * Calculate the width of the column based on the longest string
 * @param {array} list the list with the names
 * @param {string} size name of the breakpoint
 * @param {float} fontSize size of font in px
 * @param {float} rowWidth width of full row with 1 as 100%
 * @returns int
 */
function calculateColumnWidth(list, size = null, fontSize = 16, rowWidth = 1) {
  if (list.length == 0) {
    return 12;
  }

  let longestName = 0;
  list.forEach(
    (item) =>
      (longestName =
        item.length > longestName ? item.length : longestName)
  );

  let width = screen.width;
  if (size == "xs") {
    width = vuetify.preset.breakpoint.thresholds.xs;
  } else if (size == "sm") {
    width = vuetify.preset.breakpoint.thresholds.sm;
  } else if (size == "md") {
    width = vuetify.preset.breakpoint.thresholds.md;
  } else if (size == "lg") {
    width = vuetify.preset.breakpoint.thresholds.lg;
  } else if (size == "xl") {
    //
  }

  let amountOfLettersOnScreen = (width * rowWidth) / (fontSize * 0.85);
  const lettersInCol = amountOfLettersOnScreen / 12;
  const amountOfCols = Math.ceil(longestName / lettersInCol);

  if (amountOfCols > 6) return 12;
  else if (amountOfCols > 4) return 6;
  else if (amountOfCols > 3) return 4;
  else if (amountOfCols > 2) return 3;
  else return 2;
}

const stylingHelper = {
	calculateColumnWidth,
};

export default stylingHelper;