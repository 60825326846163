import { VBtn } from 'vuetify-wcag/lib/components/VBtn';
import { VCard } from 'vuetify-wcag/lib/components/VCard';
import { VCardText } from 'vuetify-wcag/lib/components/VCard';
import { VCardTitle } from 'vuetify-wcag/lib/components/VCard';
import { VCol } from 'vuetify-wcag/lib/components/VGrid';
import { VDialog } from 'vuetify-wcag/lib/components/VDialog';
import { VIcon } from 'vuetify-wcag/lib/components/VIcon';
import { VListItem } from 'vuetify-wcag/lib/components/VList';
import { VListItemContent } from 'vuetify-wcag/lib/components/VList';
import { VListItemTitle } from 'vuetify-wcag/lib/components/VList';
import { VRow } from 'vuetify-wcag/lib/components/VGrid';
import { VSimpleTable } from 'vuetify-wcag/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify-wcag/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"scrollable":"","max-width":"900px","role":_vm.dialogData.role},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VListItem,_vm._g(_vm._b({attrs:{"role":"menuitem"}},'v-list-item',attrs,false),on),[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.dialogData.linkText)}})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"card-heading","width":"100%"}}):_c(VRow,{staticClass:"flex-nowrap",attrs:{"justify":"space-between"}},[_c(VCol,[_c('h1',{staticClass:"dw-text-h5"},[_vm._v(_vm._s(_vm.title || "NO TITLE"))])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"btn-focus",attrs:{"color":"primary","title":_vm.closeText,"aria-label":_vm.closeText,"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"aria-hidden":"true"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c(VCardText,{staticClass:"text-left"},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_vm._l((_vm.detailData),function(detailItem,i){return [(detailItem.type == 'table' && detailItem.table)?_c('div',{key:'annex' + i},[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((detailItem.table.header),function(header,i){return _c('th',{key:'header_' + i,staticClass:"text-left"},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',_vm._l((detailItem.table.data),function(dataItem,j){return _c('tr',{key:'body_' + j},_vm._l((dataItem),function(field,k){return _c('td',{key:'field_' + k},[(detailItem.icon)?_c(VIcon,[_vm._v(_vm._s(detailItem.icon))]):_vm._e(),(field.link)?_c('p',[_c('a',{attrs:{"href":field.link,"target":"_blank","rel":"noopener noreferrer","aria-label":_vm.$translations.aria_labels.to_dc_text +
                          ' ' +
                          field.text,"title":_vm.$translations.aria_labels.to_dc_text +
                          ' ' +
                          field.text}},[_vm._v(_vm._s(field.text))])]):_c('p',[_vm._v(_vm._s(field.text))])],1)}),0)}),0)]},proxy:true}],null,true)})],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }