<template>
  <div>
    <div>
      <div class="main-header mb-4">
        <v-row class="mt-1">
          <v-col cols="12" v-if="loading" class="pa-0">
            <v-row v-for="i in 3" :key="i">
              <v-col :cols="(12 % j) * j" class="pa-0" v-for="j in 6" :key="j">
                <v-skeleton-loader
                  class="mx-2 my-2"
                  height="50"
                  type="card"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="errors[''] != null">
            <dw-alert
              type="error"
              class="text-left mb-0"
              :color="$helpers.color.darkModeColor('red')"
              :text="errors[''].join(', ')"
            />
          </v-col>
          <v-col
            v-for="filter in formFields"
            :key="filter.name"
            class="d-flex"
            cols="12"
            :sm="filter.size && filter.size.sm ? filter.size.sm : '6'"
            :md="filter.size && filter.size.md ? filter.size.md : '4'"
            :lg="filter.size && filter.size.lg ? filter.size.lg : '2'"
            :xl="filter.size && filter.size.xl ? filter.size.xl : '2'"
            :class="filter.type == 'dummy' ? 'ma-0 pa-0' : ''"
          >
            <div v-if="filter.type == 'staticHeader'">
              <p :class="['text-' + filter.size, filter.class]">
                {{ filter.value }}
              </p>
            </div>

            <div v-if="filter.type == 'staticText'">
              <p :class="filter.class">{{ filter.value }}</p>
            </div>

            <div v-if="filter.to_login">
              <router-link :to="{ name: 'Auth.Login' }" :class="filter.class">
                {{ filter.value }}
              </router-link>
            </div>

            <div v-if="filter.type == 'staticField' && filter.value">
              <span class="font-weight-bold dw-text-caption">{{
                filter.label
              }}</span
              ><br />
              <span
                v-if="filter.html"
                :class="filter.class"
                v-html="filter.value"
              ></span>
              <span v-else :class="filter.class">{{ filter.value }}</span>
            </div>

            <div v-if="filter.type == 'radio'">
              <span class="font-weight-bold dw-text-caption">{{
                filter.label
              }}</span>

              <v-radio-group v-model="filter.value" mandatory>
                <v-radio
                  v-for="(radio, i) in filter.values"
                  :key="i"
                  :label="radio.text"
                  :value="radio.value"
                >
                  <template v-slot:label>
                    <span v-html="radio.text"> </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>

            <div v-if="filter.type == 'checkbox'">
              <p>{{ filter.label }}</p>
              <p class="text--red" v-if="errors[filter.name] != null">
                {{ errors[filter.name] | arrayToString }}
              </p>
              <v-checkbox
                v-for="option in filter.options"
                v-model="filter.value"
                :key="option.name"
                :label="option.text"
                :value="option.value"
                :hide-details="!filter.hasValidation"
                :error="errors[filter.name]"
                error-count="3"
                :error-messages="errors[filter.name]"
                :disabled="submitLoading"
                :class="errors[filter.name] ? 'error-form' : ''"
                @update:error="hasError(filter, fieldsWithErrors, $event)"
              ></v-checkbox>
            </div>

            <v-select
              v-if="filter.type == 'select'"
              v-model="filter.value"
              :items="filter.values"
              :label="filter.label"
              :rules="filter.rules"
              :hint="filter.hint"
              :autocomplete="filter.autocomplete"
              :hide-details="!filter.hasValidation"
              :error="errors[filter.name] ? true : false"
              error-count="3"
              :error-messages="errors[filter.name]"
              outlined
              clearable
              :clearText="$translations.aria_labels.clear_icon_label"
              dense
              :disabled="submitLoading"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            ></v-select>

            <v-select
              v-if="filter.type == 'multiselect'"
              v-model="filter.value"
              :items="filter.values"
              :label="filter.label"
              :rules="filter.rules"
              :hint="filter.hint"
              :autocomplete="filter.autocomplete"
              :hide-details="!filter.hasValidation"
              :error="errors[filter.name] ? true : false"
              error-count="3"
              :error-messages="errors[filter.name]"
              outlined
              clearable
              :clearText="$translations.aria_labels.clear_icon_label"
              dense
              multiple
              :disabled="submitLoading"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            ></v-select>

            <v-text-field
              v-else-if="filter.type == 'text'"
              :label="filter.label"
              :autocomplete="filter.autocomplete"
              v-model="filter.value"
              :rules="filter.rules"
              :hint="filter.hint"
              :hide-details="!filter.hasValidation"
              :error="errors[filter.name] ? true : false"
              error-count="1"
              :error-messages="errors[filter.name]"
              outlined
              dense
              :disabled="submitLoading"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            ></v-text-field>

            <v-text-field
              v-else-if="filter.type == 'password'"
              :label="filter.label"
              :autocomplete="filter.autocomplete"
              v-model="filter.value"
              :rules="filter.rules"
              :hint="filter.hint"
              :type="filter.isVisible ? 'text' : 'password'"
              :hide-details="!filter.hasValidation"
              :error="errors[filter.name] ? true : false"
              error-count="3"
              :error-messages="errors[filter.name]"
              outlined
              dense
              :disabled="submitLoading"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            >
              <template #append>
                <v-icon
                  @click="filter.isVisible = !filter.isVisible"
                  :aria-label="
                    filter.isVisible
                      ? $translations.actions.hidePassword
                      : $translations.actions.showPassword
                  "
                  >{{ filter.isVisible ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                >
              </template>
            </v-text-field>

            <v-textarea
              v-else-if="filter.type == 'textarea'"
              :label="filter.label"
              v-model="filter.value"
              clearable
              :clearText="$translations.aria_labels.clear_icon_label"
              :rules="filter.rules"
              :autocomplete="filter.autocomplete"
              auto-grow
              dense
              :hint="filter.hint"
              :hide-details="!filter.hasValidation"
              :error="errors[filter.name] ? true : false"
              error-count="3"
              :error-messages="errors[filter.name]"
              outlined
              :disabled="submitLoading"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @change="hasError(filter, fieldsWithErrors)"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            ></v-textarea>

            <v-file-input
              v-else-if="filter.type == 'file'"
              :label="filter.label"
              v-model="filter.value"
              :hint="filter.hint"
              :rules="filter.rules"
              :autocomplete="filter.autocomplete"
              :error="errors[filter.name] ? true : false"
              error-count="3"
              :error-messages="errors[filter.name]"
              outlined
              dense
              append-icon="mdi-paperclip"
              :prepend-icon="null"
              truncate-length="50"
              :disabled="submitLoading"
              @change="(file) => onFileChange(filter, file, fieldsWithErrors)"
              persistent-hint
              :class="errors[filter.name] ? 'error-form' : ''"
              @update:error="hasError(filter, fieldsWithErrors, $event)"
            ></v-file-input>

            <v-menu
              v-else-if="filter.type == 'date'"
              :ref="filter.label"
              v-model="filter.datePickerMenu"
              :close-on-content-click="false"
              :nudge-top="-10"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="filter.readableValue"
                  :label="filter.label"
                  :autocomplete="filter.autocomplete"
                  outlined
                  :hide-details="!filter.hasValidation"
                  :error="errors[filter.name] ? true : false"
                  error-count="3"
                  :error-messages="errors[filter.name]"
                  dense
                  clearable
                  :clearText="$translations.aria_labels.clear_icon_label"
                  :disabled="submitLoading"
                  :class="errors[filter.name] ? 'error-form' : ''"
                  @update:error="hasError(filter, fieldsWithErrors, $event)"
                  @click:clear="filter.value = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="datePicker"
                first-day-of-week="1"
                v-model="filter.value"
                @change="filter.readableValue = dateFormatted(filter)"
              >
              </v-date-picker>
            </v-menu>

            <v-menu
              v-else-if="filter.type == 'time'"
              :ref="filter.label"
              v-model="filter.timePickerMenu"
              :close-on-content-click="false"
              :nudge-top="-10"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filter.value"
                  :label="filter.label"
                  append-icon="mdi-clock-outline"
                  :autocomplete="filter.autocomplete"
                  readonly
                  outlined
                  :hide-details="!filter.hasValidation"
                  :error="errors[filter.name] ? true : false"
                  error-count="3"
                  :error-messages="errors[filter.name]"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  :disabled="submitLoading"
                  :class="errors[filter.name] ? 'error-form' : ''"
                  @update:error="hasError(filter, fieldsWithErrors, $event)"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="filter.timePickerMenu"
                v-model="filter.value"
                full-width
                format="24hr"
                dense
              ></v-time-picker>
            </v-menu>

            <div v-if="filter.type == 'avg_checkbox'">
              <v-checkbox
                v-model="filter.value"
                :disabled="submitLoading"
                :hide-details="!filter.hasValidation"
                :error="errors[filter.name] ? true : false"
                error-count="3"
                :error-messages="errors[filter.name]"
                :class="errors[filter.name] ? 'error-form' : ''"
                :aria-label="$translations.aria_labels.chat_label"
              >
                <template v-slot:label>
                  <div>
                    {{ privacyLabel }}
                    <a
                      target="_blank"
                      :href="filter.url"
                      @click.stop
                      :aria-label="$translations.aria_labels.privacy_link_label"
                      :title="newTabText"
                      class="underlined"
                    >
                      {{ privacyLinkLabel }}
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </div>

            <div v-if="filter.type == 'dummy'"></div>

            <!-- loop over fields -->
          </v-col>
        </v-row>

        <v-row v-if="!isDialog" justify="end">
          <v-col>
            <v-btn color="primary" @click="submitData" class="btn-focus"> </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../../axios/api";
import { rules } from "../../../helpers/validation.js";
import moment from "moment";

export default {
  name: "General.Forms.FormFields",
  data() {
    return {
      loading: false,
      submitLoading: false,
      errors: {},
      formFields: [],
      postUrl: "",

      hasValidation: false,

      fieldsWithErrors: [],
      datePickerMenu: false,
      timePickerMenu: false,
    };
  },
  props: {
    moduleData: Object,
    module: String,
    hexkey: String,
    isDialog: Boolean,
    isVisible: Boolean,
    requestParams: Object,
  },
  computed: {
    newTabText: function () {
      return this.$store.getters.getTranslations.new_tab_text;
    },
    privacyLabel: function () {
      return this.$store.getters.getTranslations.privacy_label;
    },
    privacyLinkLabel: function () {
      return this.$store.getters.getTranslations.privacy_link_label;
    },
    dateFormat: function () {
      return this.$store.getters.getDateFormat;
    },
  },
  filters: {
    arrayToString(array) {
      return array.join(", ");
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    isVisible: function (isVisible) {
      if (this.isDialog && isVisible) {
        this.fetchData();
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    },
    fieldsWithErrors: function (fieldsWithErrors) {
      this.$emit("errorChange", fieldsWithErrors);
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;

      const args = this.requestParams
        ? this.requestParams
        : { hexkey: this.hexkey };

      const apiCall = await import(`../../../axios/${this.module}`);
      apiCall.default
        .getFormData({ ...args })
        .then((response) => {
          this.formFields = response.formfields.map((field) => {
            let fieldRules = [];
            if ("rules" in field) {
              this.hasValidation = true;

              fieldRules = field.rules.map((rule) => {
                const splittedRule = rule.split(",");
                const ruleName = splittedRule.splice(0, 1);
                if (ruleName in rules) return rules[ruleName](...splittedRule);
              });
            }

            if (field.hint && field.hint.length) {
              this.hasValidation = true;
            }

            const fieldData = {
              label: field.label,
              name: field.name,
              type: field.type,
              value: field.value,
              autocomplete: field.autocomplete,
              size: field.size,
              url: field.url,
              class: field.class,
              rules: fieldRules,
              hint: field.hint,
              html: field.html,
              to_login: field.to_login,
              hasValidation:
                fieldRules.length > 0 || (field.hint && field.hint.length > 0),
            };

            const getValues = (values) => {
              const selectValues = [];
              for (let index = 0; index < values.length; index = index + 2) {
                selectValues.push({
                  text: values[index + 1],
                  value: values[index],
                });
              }

              return selectValues;
            };

            if (
              field.type == "select" ||
              field.type == "radio" ||
              field.type == "multiselect"
            ) {
              fieldData.values = getValues(field.values);
              fieldData.value = field.value;
            }

            if (field.type == "checkbox") {
              fieldData.options = getValues(field.options);
            }

            if (field.type == "date") {
              // Dashed iso date required from backend YYYY-MM-DD
              fieldData.value = field.value != null ? field.value : null;
              fieldData.readableValue = this.dateFormatted(field);
              this.datePickerMenu = true;
            }

            if (field.type == "time") {
              fieldData.value = field.value ? field.value : null;
              this.timePickerMenu = true;
            }

            if (field.type == "password") {
              fieldData.isVisible = false;
            }

            if (
              field.rules &&
              field.rules.length > 0 &&
              field.rules.includes("required")
            ) {
              fieldData.label = fieldData.label + "*";
            }
            return fieldData;
          });
          this.postUrl = response.posturl;
        })
        .catch((errors) => {
          this.errors = errors.data;
        })
        .then(() => (this.loading = false));
    },
    submitData() {
      this.submitLoading = true;
      this.$emit("startSubmit");

      let sendData = {};
      this.formFields.forEach((field) => {
        switch (field.type) {
          case "date":
            sendData[field.name] =
              field.value != null
                ? moment(field.value, "YYYY-MM-DD").format("DD-MM-YYYY")
                : "";
            break;

          case "time":
            sendData[field.name] = field.value != null ? field.value : "";
            break;

          case "file":
            sendData[field.name] = {
              name: field.fileName,
              data: field.fileData,
              size: field.fileSize,
            };
            break;

          case "staticField":
            // do nothing
            break;

          default:
            sendData[field.name] =
              typeof field.value === "object"
                ? field.value
                  ? field.value.join(",")
                  : ""
                : field.value;
            break;
        }
      });

      if (this.module == "general/support") {
        sendData["url"] = window.location.href;
      }

      // Made for agenda
      if (this.requestParams) {
        sendData = { ...sendData, ...this.requestParams };
      }

      request({
        url: this.postUrl,
        method: "POST",
        data: sendData,
      })
        .then(() => {
          if (this.isDialog) {
            this.loading = false;
            this.submitLoading = false;
            this.errors = {};
            this.formFields = [];
            this.postUrl = "";
          }

          this.$emit("submit");
        })
        .catch((errors) => {
          this.errors = errors.data;
          this.$emit("errorSubmit");
        })
        .then(() => (this.submitLoading = false));
    },
    dateFormatted: (filter, dateFormat = "DD-MM-YYYY") => {
      return filter.value
        ? moment(filter.value, "YYYY-MM-DD").format(dateFormat)
        : null;
    },
    hasError: (field, fieldsWithErrors, event = false) => {
      if (event) {
        if (!fieldsWithErrors.includes(field.name)) {
          fieldsWithErrors.push(field.name);
        }
      } else {
        fieldsWithErrors.splice(fieldsWithErrors.indexOf(field.name), 1);
      }
    },
    onFileChange: (filter, file, fieldsWithErrors) => {
      filter.loading = true;

      // Dirty fix for bug: https://github.com/vuetifyjs/vuetify/issues/7876
      // Issue will not be fixed for vue2
      fieldsWithErrors.pop(filter.name);
      // End dirty fix
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        filter.fileData = e.target.result;
        filter.fileName = file.name;
        filter.fileSize = file.size;
      };

      // TODO: Show loading progress in file upload field
      // fileReader.onprogress = (data) => {
      //   if (data.lengthComputable) {
      //     var progress = parseInt((data.loaded / data.total) * 100, 10);
      //     console.log(progress);
      //   }
      // };

      fileReader.loadend = () => {
        filter.loading = false;
      };

      fileReader.readAsDataURL(file);
    },
  },
};
</script>
<style lang="less" scoped>
.underlined {
  text-decoration: underline !important;
}
</style>
