import request from "../api";

function getModuleData() {
  return request({
    url: "/vji/party/calendar/action=moduledata",
    method: "GET",
  });
}

function getDataList({ start, end }) {
  return request({
    url: `/vji/party/calendar/action=datalist/start=${start}/end=${end}`,
    method: "GET",
  });
}

function getDataDetails({ hexkey }) {
  return request({
    url: `/vji/party/calendar/action=detaildata/gd=${hexkey}`,
    method: "GET",
  });
}

const partyCalendarService = {
  getModuleData,
  getDataList,
  getDataDetails,
};

export default partyCalendarService;
