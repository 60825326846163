export default [
	{
		path: "/auth",
		name: "Auth.Main",
		component: function () {
			return import(
				/* webpackChunkName: "auth" */ "../views/general/auth"
			);
		},
		children: [
			{
				path: "login/:needsLogin?",
				name: "Auth.Login",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/login"
					),
			},
			{
				path: "2fa/:request_id",
				name: "Auth.2FA",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/2fa"
					),
			},
			{
				path: "sso/:sso_id?",
				name: "Auth.SSO",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/sso"
					),
			},
			{
				path: "reset-password/:key?/:token?",
				meta: {
          helpTag: "auth:reset-password",
					// needsAuth: true,
        },
				name: "Auth.ResetPassword",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/resetPassword"
					),
			},
			{
				path: "forgot-password",
				meta: {
          helpTag: "auth:forgot-password",
        },
				name: "Auth.ForgotPassword",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/forgotPassword"
					),
			},
			{
				path: "new-password",
				meta: {
          helpTag: "auth:new-password",
        },
				name: "Auth.NewPassword",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/newPassword"
					),
			},
			{
				path: "register",
				meta: {
          helpTag: "auth:register",
        },
				name: "Auth.Register",
				component: () =>
					import(
						/* webpackChunkName: "auth" */ "../views/general/auth/register"
					),
			},
		],
	},
];
