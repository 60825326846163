<template>
  <v-row>
    <v-btn
      id="menu-bar-icon"
      icon
      :title="$translations.menu_icon_label"
      v-if="
        $vuetify.breakpoint.mdAndDown &&
        this.$route.name &&
        !this.$route.name.includes('Auth')
      "
      :aria-label="$translations.menu_icon_label"
      @click.stop="updateDrawer(!drawer)"
      class="btn-focus"
    >
      <v-icon aria-hidden="true">mdi-menu</v-icon>
    </v-btn>
    <v-btn
      v-if="publicAllowed && this.$route.name && this.$route.name.includes('Auth')"
      exact
      :to="{ name: 'Public.Homepage' }"
      icon
      color="primary"
      :aria-label="$translations.homepage_label"
      class="btn-focus"
    >
      <v-icon aria-hidden="true">mdi-home</v-icon>
    </v-btn>
    <div class="h-75" v-if="$vuetify.breakpoint.mdAndUp">
      <a :href="clientUrl" :aria-label="$translations.client_logo_link_label">
        <v-img
          :alt="$translations.client_logo_label"
          :src="baseUrl + logo"
        ></v-img>
      </a>
    </div>

    <v-spacer></v-spacer>
    <div class="mt-n8">
      <a
        class="skip-link mt-0 mt-lg-8"
        ref="skipLink"
        href="#main"
        :aria-label="translations.skiplink"
        >{{ translations.skiplink }}</a
      >
    </div>

    <v-btn
      v-if="section == 'Public' && searchMaat"
      exact
      :to="{ name: 'Public.MaatSearch' }"
      icon
      :aria-label="$translations.search_maat_label"
      :title="$translations.search_maat_label"
      class="btn-focus"
    >
      <v-icon aria-hidden="true">mdi-magnify</v-icon>
    </v-btn>

    <div v-if="(section != 'Public' && searchMaat || !searchMaat) && (notSearchPage || isSimpleSearch)">
      <div @mouseenter="focusSearch" @mouseleave="searchMouseLeave">
        <v-btn
          icon
          :title="$translations.search_label"
          :aria-label="$translations.search_label"
          class="btn-focus"
          @focus="focusSearch"
          v-if="!enableSearch"
          ><v-icon aria-hidden="true">mdi-magnify</v-icon></v-btn
        >
        <label for="search-field" class="d-sr-only">{{
          $translations.search_label
        }}</label>
        <v-text-field
          id="search-field"
          name="search-field"
          v-if="enableSearch"
          v-model="searchValue"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$translations.search_label"
          :title="$translations.search_label"
          outlined
          dense
          hide-details
          v-on:keyup.enter="toSearch"
        ></v-text-field>
      </div>
    </div>

    <v-btn
      v-if="isLoggedIn"
      icon
      :title="
        newChangelog
          ? $translations.changelog_label
          : $translations.new_changelog_label
      "
      :aria-label="
        newChangelog
          ? $translations.changelog_label
          : $translations.new_changelog_label
      "
      :to="{ name: 'General.Changelog' }"
      class="ml-2 btn-focus"
    >
      <v-badge v-if="newChangelog" overlap color="red" dot aria-hidden="true">
        <v-icon aria-hidden="true">mdi-lightbulb-on</v-icon>
      </v-badge>
      <v-icon v-else aria-hidden="true">mdi-lightbulb-on</v-icon>
    </v-btn>

    <generalForm
      v-if="isLoggedIn"
      module="general/support"
      :title="translations.support_label"
      :dialog="{
        linkIcon: 'mdi-headset',
        iconClass: 'mx-auto',
        buttonColor: $helpers.color.darkModeColor('grey', 1),
        ariaLabel: translations.support_label,
        submitText: translations.send_label,
      }"
    >
    </generalForm>

    <accessibilityMenu />

    <div
      v-if="
        !isLoggedIn &&
        (helpAvailable || languages.length || customerLinks.length)
      "
    >
      <v-menu bottom offset-y :close-on-content-click="false" role="menu">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            elevation="0"
            v-on="on"
            :title="$translations.setting_options_menu_label"
            :aria-label="$translations.setting_options_menu_label"
            role="menu"
            @click="expandMenuOpen = !expandMenuOpen"
            :aria-expanded="expandMenuOpen ? 'true' : 'false'"
            class="btn-focus"
          >
            <v-icon aria-hidden="true">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-group
            v-if="languages && languages.length"
            v-model="languagesModel"
            group
          >
            <template v-slot:activator>
              <v-list-item-title
                v-text="$translations.language_label"
              ></v-list-item-title>
            </template>

            <v-list-item
              v-for="(lang, i) in languages"
              :key="i"
              :tabindex="languagesModel ? '0' : '-1'"
              @click="setLang(lang.code)"
              role="menuitem"
            >
              <v-list-item-title
                class="pl-4 text-wrap"
                :title="lang.language"
                :aria-label="lang.language"
                >{{ lang.language }}</v-list-item-title
              >
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="customerLinks && customerLinks.length"
            v-model="customerLinksModel"
          >
            <template v-slot:activator>
              <v-list-item-title
                v-text="$translations.customer_links"
              ></v-list-item-title>
            </template>

            <v-list-item
              v-for="(link, j) in customerLinks"
              :key="j"
              :href="link.link"
              :target="link.target"
              :tabindex="customerLinksModel ? '0' : '-1'"
              :aria-label="link.title"
              :title="link.title"
              role="menuitem"
            >
              <v-list-item-title class="pl-4 text-wrap">{{
                link.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <template v-if="helpAvailable">
            <v-list-item
              target="_blank"
              v-if="helpTag"
              :href="'/user/help/subject=app:' + helpTag"
              exact
              :aria-label="$translations.aria_labels.go_to_manual_label"
              role="menuitem"
            >
              <v-list-item-title
                v-text="$translations.help_label"
              ></v-list-item-title>
            </v-list-item>

          </template>
        </v-list>
      </v-menu>
    </div>

    <div v-if="isLoggedIn">
      <v-menu bottom offset-y :max-width="220" :nudge-left="100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            v-bind="attrs"
            v-on="on"
            :title="userName"
            :aria-label="$translations.aria_labels.logged_desc_menu_label"
            class="btn-focus"
          >
            <v-avatar size="32" class="mr-2">
              <v-img
                :src="baseUrl + avatar"
                :alt="$translations.aria_labels.profile_img_alt"
              ></v-img>
            </v-avatar>
            {{ userName }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item role="menuitem">
              <v-list-item-avatar>
                <v-img
                  :src="baseUrl + avatar"
                  :alt="$translations.aria_labels.profile_img_al"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ userName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              exact
              :to="{ name: 'General.Preferences' }"
              :aria-label="$translations.settings_label"
              role="menuitem"
            >
              <v-list-item-title
                v-text="$translations.settings_label"
              ></v-list-item-title>
            </v-list-item>

            <v-list-group
              v-if="languages && languages.length"
              v-model="languagesModel"
              :aria-label="$translations.language_label"
            >
              <template v-slot:activator>
                <v-list-item-title
                  v-text="$translations.language_label"
                ></v-list-item-title>
              </template>

              <v-list-item
                v-for="(lang, i) in languages"
                :key="i"
                @click="setLang(lang.code)"
                :tabindex="languagesModel ? '0' : '-1'"
                role="menuitem"
              >
                <v-list-item-title
                  v-text="lang.language"
                  class="pl-4"
                  :title="lang.language"
                ></v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
              v-if="customerLinks && customerLinks.length"
              v-model="customerLinksModel"
            >
              <template v-slot:activator>
                <v-list-item-title
                  v-text="$translations.customer_links"
                ></v-list-item-title>
              </template>

              <v-list-item
                v-for="(link, j) in customerLinks"
                :key="j"
                :href="link.link"
                :target="link.target"
                :tabindex="customerLinksModel ? '0' : '-1'"
                role="menuitem"
              >
                <v-list-item-title
                  v-text="link.title"
                  class="pl-4"
                  :title="link.title"
                ></v-list-item-title>
              </v-list-item>
            </v-list-group>

            <DataDialog
              v-if="paperstatsAvailable"
              module="general/paperstats"
              :dialogData="{
                linkText: translations.paperstats_label,
                buttonColor: $helpers.color.darkModeColor('grey', 1),
                role: 'menuitem',
              }"
            ></DataDialog>

            <template v-if="helpAvailable">
              <v-list-item
                target="_blank"
                v-if="helpTag"
                :href="'/user/help/subject=app:' + helpTag"
                exact
                :aria-label="$translations.aria_labels.go_to_manual_label"
                role="menuitem"
              >
                <v-list-item-title
                  v-text="$translations.help_label"
                ></v-list-item-title>
              </v-list-item>

              <v-list-item
                target="_blank"
                v-else
                href="https://manual.docwolves.nl/product-parlaeus/startpagina/"
                exact
                :aria-label="$translations.aria_labels.go_to_manual_label"
                role="menuitem"
              >
                <v-list-item-title
                  v-text="$translations.help_label"
                ></v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item @click="logout" role="menuitem">
              <v-list-item-title
                v-text="$translations.logout_label"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <!-- login button -->
    <div class="my-auto" id="login" v-if="sso.length > 0 && !isLoggedIn">
      <div v-if="sso.length == 1">
        <v-btn
          block
          outlined
          :title="translations.login_label"
          color="primary"
          :href="sso[0].link | fullPath"
          :aria-label="translations.login_label"
          class="btn-focus"
        >
          <v-icon left aria-hidden="true">mdi-login</v-icon>
          {{ translations.login_label }}
        </v-btn>
      </div>
      <v-menu v-else bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            outlined
            v-bind="attrs"
            v-on="on"
            :title="translations.login_label"
            color="primary"
            :aria-label="translations.login_label"
            class="btn-focus"
          >
            <v-icon left aria-hidden="true">mdi-login</v-icon>
            {{ translations.login_label }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in sso"
            :key="index"
            :href="item.link | fullPath"
            exact
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-row>
</template>

<script>
import generalAuthService from "../../axios/auth";
import generalForm from "../general/forms/form";
import DataDialog from "../general/dialogs/dataDialog.vue";
import accessibilityMenu from "../general/accessibility/menu.vue";

export default {
  data() {
    return {
      helpTag: null,
      enableSearch: this.$vuetify.breakpoint.mdAndUp,
      searchValue: null,

      languagesModel: false,
      customerLinksModel: false,
      expandMenuOpen: false,
    };
  },
  props: {
    drawer: Boolean,
    userName: String,
    section: String,
  },

  computed: {
    clientUrl() {
      return this.$store.getters.getClientUrl;
    },
    notSearchPage() {
      return this.$route && this.$route.name
        ? !this.$route.name.includes("Search")
        : true;
    },
    drawerModel() {
      return this.$vuetify.breakpoint.lgAndUp ? true : this.drawer;
    },
    miniDrawer() {
      return this.$vuetify.breakpoint.mdAndDown ? false : this.drawer;
    },
    baseUrl() {
      return process.env.NODE_ENV == "development"
        ? process.env.VUE_APP_BASEURL
        : window.location.origin;
    },
    languages() {
      return this.$store.getters.getLanguages ?? [];
    },
    currentLanguage() {
      return this.languages.find(
        (lang) => lang.code == this.$store.getters.getCurrentLanguage
      );
    },
    isLoggedIn() {
      return this.$store.getters.getSID != null;
    },
    sso() {
      return this.$store.getters.getSSO;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    customerLinks() {
      return this.$store.getters.getCustomerLinks;
    },
    colors() {
      return this.$store.getters.getColors;
    },
    logo() {
      return this.$store.getters.getLogo;
    },
    paperstatsAvailable() {
      return this.$store.getters.getPaperstatsAvailable;
    },
    helpAvailable() {
      return this.$store.getters.getHelpAvailable;
    },
    avatar() {
      return this.$store.getters.getAvatar;
    },
    isSimpleSearch() {
      return this.$store.getters.isSimpleSearch;
    },
    newChangelog() {
      return this.$store.getters.getNewChangelog;
    },
    publicAllowed() {
      return this.$store.getters.getPublicAllowed;
    },
    searchMaat() {
      return this.$store.getters.getSearchMaat;
    },
  },
  watch: {
    $route() {
      this.setHelpTag();
    },
  },
  filters: {
    fullPath(value) {
      return (
        (process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_BASEURL
          : window.location.origin) + value
      );
    },
  },
  components: {
    generalForm,
    DataDialog,
    accessibilityMenu,
  },
  methods: {
    setHelpTag() {
      this.helpTag = null;
      const matchedRoutes = this.$route.matched;
      for (let i = matchedRoutes.length - 1; i >= 0; i--) {
        const element = matchedRoutes[i];
        if (!this.helpTag && "meta" in element && "helpTag" in element.meta) {
          this.helpTag = element.meta.helpTag;
        }
      }
    },
    searchMouseLeave() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.enableSearch = false;
      }
    },
    logout() {
      generalAuthService.doLogout().then(() => {
        this.$router.push({ name: "Public.Homepage" });
        this.$store.commit("setUsername", null);

        // be sure that router params are gone
        const self = this;
        setTimeout(() => {
          self.$store.commit("setSID", null);
        }, 500);
      });
    },
    setLang(langCode) {
      this.$store.dispatch("setLang", langCode);
    },
    focusSearch() {
      this.enableSearch = true;
      setTimeout(() => {
        document.getElementById("search-field").focus();
      }, 100);
    },
    toSearch() {
      const search = this.searchValue;

      let searchSection = "Public";
      const routeName = this.$route.name;
      if (routeName) {
        // dot is needed, otherwise councilperiod is counted as council
        if (routeName.includes("Council.")) {
          searchSection = "Council";
        } else if (routeName.includes("College.")) {
          searchSection = "College";
        } else if (routeName.includes("General")) {
          searchSection = this.isLoggedIn ? this.section : "Public";
        }
      }

      this.searchValue = "";
      this.$router.push({
        name: searchSection + ".Search",
        query: { q: search },
      });
    },
    updateDrawer(state) {
      this.$emit("updateDrawer", state);
    },
  },
};
</script>

<style></style>
