<template>
  <div>
    <v-btn
      :title="$translations.accessibility.menuTitle"
      icon
      @click="menuIsOpened = !menuIsOpened"
      :aria-label="$translations.accessibility.menuTitle"
      :aria-expanded="menuIsOpened ? 'true' : 'false'"
      class="btn-focus"
    >
      <v-icon aria-hidden="true"> mdi-human </v-icon>
    </v-btn>
    <v-card
      class="menu-card accessibility-menu"
      left
      top
      width="500px"
      v-if="menuIsOpened"
    >
      <v-card-text>
        <v-alert
          v-if="menuError"
          class="ma-4"
          :color="darkModeTextColor('red')"
          type="error"
          dense
          text
          >{{ menuError }}</v-alert
        >

        <h1 class="font-weight-bold">
          {{ $translations.accessibility.menuTitle }}
        </h1>

        <!-- scaling -->
        <v-row class="mt-4">
          <v-col cols="6">
            <v-card flat outlined>
              <v-card-title class="mx-auto mb-3">
                <v-icon left aria-hidden="true">mdi-arrow-expand-all</v-icon>
                <h2 class="dw-text-h6">
                  {{ $translations.accessibility.contentScaling }}
                </h2>
              </v-card-title>
              <v-card-text class="text-center">
                <v-row class="d-flex" justify="space-around">
                  <v-btn
                    :title="$translations.accessibility.minus"
                    icon
                    @click="lower('contentScaling')"
                    class="btn-focus"
                  >
                    <v-icon aria-hidden="true">mdi-minus</v-icon>
                    <span class="d-sr-only"
                      >{{ $translations.accessibility.contentScaling }}
                      {{ $translations.accessibility.minus }}</span
                    >
                  </v-btn>
                  <span
                    class="dw-text-h5 font-weight-bold my-auto"
                    tabindex="0"
                  >
                    {{ settings.contentScaling | procentageValue }}
                  </span>
                  <v-btn
                    :title="$translations.accessibility.add"
                    icon
                    @click="higher('contentScaling')"
                    class="btn-focus"
                  >
                    <v-icon aria-hidden="true">mdi-plus</v-icon>
                    <span class="d-sr-only"
                      >{{ $translations.accessibility.contentScaling }}
                      {{ $translations.accessibility.add }}</span
                    >
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- font size -->
          <v-col cols="6">
            <v-card flat outlined>
              <v-card-title class="mx-auto mb-3">
                <v-icon left aria-hidden="true">mdi-format-size</v-icon>
                <h2 class="dw-text-h6">
                  {{ $translations.accessibility.fontSize }}
                </h2>
              </v-card-title>
              <v-card-text class="text-center">
                <v-row class="d-flex" justify="space-around">
                  <v-btn
                    :title="$translations.accessibility.minus"
                    icon
                    @click="lower('fontSize')"
                    class="btn-focus"
                  >
                    <v-icon aria-hidden="true">mdi-minus</v-icon>
                    <span class="d-sr-only"
                      >{{ $translations.accessibility.fontSize }}
                      {{ $translations.accessibility.minus }}</span
                    >
                  </v-btn>
                  <span
                    class="dw-text-h5 font-weight-bold my-auto"
                    tabindex="0"
                    >{{ settings.fontSize | procentageValue }}</span
                  >
                  <v-btn
                    :title="$translations.accessibility.add"
                    icon
                    @click="higher('fontSize')"
                    class="btn-focus"
                  >
                    <v-icon aria-hidden="true">mdi-plus</v-icon>
                    <span class="d-sr-only"
                      >{{ $translations.accessibility.fontSize }}
                      {{ $translations.accessibility.add }}</span
                    >
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- line height -->
          <!-- <v-col cols="6">
            <v-card>
              <v-card-title class="mx-auto mb-3">
                <v-icon left>mdi-format-line-spacing</v-icon>
                <h2 class="dw-text-h6">{{ $translations.accessibility.lineHeight }}</h2>
              </v-card-title>
              <v-card-text class="text-center">
                <v-row class="d-flex" justify="space-around">
                  <v-icon
                    large
                    :title="$translations.accessibility.minus"
                    @click="lower('lineHeight')"
                    >mdi-minus</v-icon
                  >
                  <h2>{{ settings.lineHeight | procentageValue }}</h2>
                  <v-icon
                    large
                    :title="$translations.accessibility.add"
                    @click="higher('lineHeight')"
                    >mdi-plus</v-icon
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col> -->

          <!-- letter spacing -->
          <!-- <v-col cols="6">
            <v-card>
              <v-card-title class="mx-auto mb-3">
                <v-icon left>mdi-format-letter-spacing</v-icon>
                <h2 class="dw-text-h6">
                  {{ $translations.accessibility.letterSpacing }}
                </h2>
              </v-card-title>
              <v-card-text class="text-center">
                <v-row class="d-flex" justify="space-around">
                  <v-icon
                    large
                    :title="$translations.accessibility.minus"
                    @click="lower('letterSpacing')"
                    >mdi-minus</v-icon
                  >
                  <span class="dw-text-h5 font-weight-bold">{{
                    settings.letterSpacing | procentageValue
                  }}</span>
                  <v-icon
                    large
                    :title="$translations.accessibility.add"
                    @click="higher('letterSpacing')"
                    >mdi-plus</v-icon
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col> -->

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="darkmode"
                inset
                :label="$translations.accessibility.darkmode"
              >
              </v-switch>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="highSaturation"
                inset
                :label="$translations.accessibility.highSaturation"
              ></v-switch>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="monochrome"
                inset
                :label="$translations.accessibility.monochrome"
              ></v-switch>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="lowSaturation"
                inset
                :label="$translations.accessibility.lowSaturation"
              ></v-switch>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="highContrast"
                inset
                :label="$translations.accessibility.highContrast"
              ></v-switch>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card flat outlined>
              <v-switch
                hide-details
                class="ma-2 black-label"
                v-model="lightContrast"
                inset
                :label="$translations.accessibility.lightContrast"
              ></v-switch>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-list-item>
      </v-list> -->
    <!-- </v-menu> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuError: false,
      menuLoading: false,
      menuIsOpened: false,

      settings: this.$store.getters.getSettings,

      darkmode: this.$store.getters.getDarkmode,
      highSaturation: this.$store.getters.getHighSaturation,
      monochrome: this.$store.getters.getMonochrome,
      lowSaturation: this.$store.getters.getLowSaturation,
      highContrast: this.$store.getters.getHighContrast,
      lightContrast: this.$store.getters.getLightContrast,

      root: document.querySelector(":root"),
    };
  },
  filters: {
    procentageValue(value) {
      return value + "%";
    },
  },
  mounted() {
    if (this.darkmode) this.$vuetify.theme.dark = true;
    if (this.highSaturation) document.body.classList.add("high-saturation");
    if (this.monochrome) document.body.classList.add("monochrome");
    if (this.lowSaturation) document.body.classList.add("low-saturation");
    if (this.highContrast) document.body.classList.add("high-contrast");
    if (this.lightContrast) document.body.classList.add("light-contrast");
    if (this.settings.contentScaling) {
      const scaling = 1 + this.settings.contentScaling / 100;
      this.root.style.zoom = scaling;
    };
    if (this.settings.fontSize) {
      const size = 16 * (1 + this.settings.fontSize / 100) + "px";
      this.root.style.fontSize = size;
      this.root.style.lineHeight = size;
    };
  },
  watch: {
    settings: {
      handler(val) {
        this.setSettings(val);
      },
      deep: true,
    },
    darkmode(value) {
      this.$store.commit("setDarkmode", value);
      this.$vuetify.theme.dark = value;
    },
    highSaturation(value) {
      this.$store.commit("setHighSaturation", value);
      if (value) {
        this.monochrome = false;
        this.lowSaturation = false;
        this.highContrast = false;
        this.lightContrast = false;
        document.body.classList.add("high-saturation");
      } else {
        document.body.classList.remove("high-saturation");
      }
    },
    monochrome(value) {
      this.$store.commit("setMonochrome", value);
      if (value) {
        this.highSaturation = false;
        this.lowSaturation = false;
        this.highContrast = false;
        this.lightContrast = false;
        document.body.classList.add("monochrome");
      } else {
        document.body.classList.remove("monochrome");
      }
    },
    lowSaturation(value) {
      this.$store.commit("setLowSaturation", value);
      if (value) {
        this.highSaturation = false;
        this.monochrome = false;
        this.highContrast = false;
        this.lightContrast = false;
        document.body.classList.add("low-saturation");
      } else {
        document.body.classList.remove("low-saturation");
      }
    },
    highContrast(value) {
      this.$store.commit("setHighContrast", value);
      if (value) {
        this.highSaturation = false;
        this.monochrome = false;
        this.lowSaturation = false;
        this.lightContrast = false;
        document.body.classList.add("high-contrast");
      } else {
        document.body.classList.remove("high-contrast");
      }
    },
    lightContrast(value) {
      this.$store.commit("setLightContrast", value);
      if (value) {
        this.highSaturation = false;
        this.monochrome = false;
        this.lowSaturation = false;
        this.highContrast = false;
        document.body.classList.add("light-contrast");
      } else {
        document.body.classList.remove("light-contrast");
      }
    },
  },
  methods: {
    higher(item) {
      const newValue = this.settings[item] + 5;
      this.settings[item] = newValue;
      this.$store.commit("setSettings", { name: item, value: newValue });
    },
    lower(item) {
      const newValue = this.settings[item] - 5;
      this.settings[item] = newValue;
      this.$store.commit("setSettings", { name: item, value: newValue });
    },
    setSettings(settings) {
      Object.keys(settings).forEach((key) => {
        const value = settings[key];

        switch (key) {
          case "contentScaling":
            this.setContentScaling(value);
            break;

          case "fontSize":
            this.setFontSize(value);
            break;

          case "letterSpacing":
            this.setLetterSpacing(value);
            break;

          case "lineHeight":
            this.setLineHeight(value);
            break;

          default:
            break;
        }
      });
    },
    setContentScaling(value) {
      this.root.style.zoom = 1 + value / 100;
    },
    setFontSize(value) {
      const size = 16 * (1 + value / 100) + "px";
      this.root.style.fontSize = size;
      this.root.style.lineHeight = size;
    },
    setLetterSpacing(value) {
      this.root.style.letterSpacing = (value / 100) * 2 + "px";
    },
    setLineHeight(value) {
      this.root.style.lineHeight =
        parseInt(this.root.style.fontSize) * (1 + value / 100) + "px";
    },
  },
};
</script>

<style>
.accessibility-menu {
  position: absolute;
  top: 10;
  right: 1vw;
  z-index: 10;
}

body.low-saturation {
  filter: saturate(50%);
  -webkit-filter: saturate(50%);
  -moz-filter: saturate(50%);
  -ms-filter: saturate(50%);
  -o-filter: saturate(50%);
}

body.high-saturation {
  filter: saturate(200%);
  -webkit-filter: saturate(200%);
  -moz-filter: saturate(200%);
  -ms-filter: saturate(200%);
  -o-filter: saturate(200%);
}

body.monochrome {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

body.high-contrast {
  filter: contrast(135%);
  -webkit-filter: contrast(135%);
  -moz-filter: contrast(135%);
  -ms-filter: contrast(135%);
  -o-filter: contrast(135%);
}

body.light-contrast {
  filter: contrast(75%);
  -webkit-filter: contrast(75%);
  -moz-filter: contrast(75%);
  -ms-filter: contrast(75%);
  -o-filter: contrast(75%);
}

.black-label label.v-label.theme--light {
  color: black;
}
</style>