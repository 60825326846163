export default [
	{
		path: "decision",
		meta: {
      helpTag: "council:decision",
    },
    component: () =>
			import(
				"../../views/general/decision"
			),
			children: [
				// {
				// 	path: "",
				// 	name: "Council.Decision",
				// 	component: () =>
				// 		import(
				// 			"../../views/council-public/decision/list"
				// 		),
				// },
				{
					path: ":hexkey/:bsi_hexkey([a-z0-9]{32})?",
					name: "Council.Decision.Details",
					component: () =>
						import(
							"../../views/general/decision/decision.vue"
						),
				},
			],
  },
];