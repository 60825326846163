import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import accessibility from "./modules/accessibility";
import calendarCouncil from "./modules/calendar-council";
import calendarPublic from "./modules/calendar-public";
import councilPeriod from "./modules/councilPeriod";
import general from "./modules/general";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		accessibility,
		calendarCouncil,
		calendarPublic,
		councilPeriod,
		general,
		user,
	},
	plugins: [
		createPersistedState({
			key: "docwolves",
		}),
	], // makes store survives a reload
});
