import request from "../api";

function doLoadChat() {
  return request({
    url: "/vji/general/chat",
    method: "GET",
  });
}
const generalChatService = {
  doLoadChat,
};

export default generalChatService;
