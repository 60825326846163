import vuetify from "../plugins/vuetify";

/**
 * automatically determen the correct text color for the active light/dark mode
 * @param {string} color the color of the text
 * @param {string|int} strength the strength to lighten or darken the text color
 * @param {boolean} inverse indicates if the lightening and darkening of the text color is reversed
 * @returns string
 */
function darkModeTextColor(color, strength = 3, inverse = false){
  return vuetify.framework.theme.isDark != inverse ? `${color}--text text--lighten-${strength}` : `${color}--text text--darken-${strength}`;
}

/**
 * automatically determen the correct color for the active light/dark mode
 * @param {string} color the color
 * @param {string|int} strength the strength to lighten or darken the color
 * @param {boolean} inverse indicates if the lightening and darkening of the color is reversed
 * @returns string
 */
function darkModeColor(color, strength = 3, inverse = false){
  return vuetify.framework.theme.isDark != inverse ? `${color} lighten-${strength}` : `${color} darken-${strength}`;
}

const colorsHelper = {
  darkModeTextColor,
  darkModeColor
};

export default colorsHelper;



