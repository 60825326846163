import generalService from "../../axios/general.js";

const getDefaultState = () => {
  return {
    isid: null,
		name: null,
		avatar: null,
		defaultSection: null,
  };
};

const state = getDefaultState();

const getters = {
	getSID: (state) => state.sid,
	getUsername: (state) => state.name,
	getAvatar: (state) => state.avatar,
	getDefaultSection: (state) => state.defaultSection,
};

const actions = {
	fetchUserData({ commit }){
		generalService.getUserData().then((response) => {
			commit("setUsername", response.name);
			commit("setAvatar", response.avatar);
			commit("setDefaultSection", response.section);
		});
	},
	resetUserState ({ commit }) {
    commit('resetUserState');
  },
};

const mutations = {
	setSID: (state, sid) => (state.sid = sid),
	setUsername: (state, name) => (state.name = name),
	setAvatar: (state, avatar) => (state.avatar = avatar),
	setDefaultSection: (state, defaultSection) => (state.defaultSection = defaultSection),
	resetUserState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  }
};

export default { state, getters, actions, mutations };
